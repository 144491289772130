*::-webkit-scrollbar {
  height: 2px;
}
.container {
  background-color: #fff;
  width: 100% !important;
  padding: 1rem 1rem;
  overflow-x: hidden;
  overflow: hidden;
}

.quotations-card-container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
@media (min-width: 600px) {
  .container {
    padding: 1rem 2rem;
  }
  .quotations-card-container {
    flex-flow: row wrap;
  }
}
.button-container {
  margin-top: 3rem;
}

.quotations-topbar {
  justify-content: space-between;
}
@media (min-width: 600px) {
  .button-container {
    margin-top: 0rem;
  }
}
.quotations-card {
  background-color: #f9f9f9;
  width: 100%;
  padding: 1.3rem;
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: space-between;
}
.quotations-card b {
  text-transform: capitalize;
}
.white {
  background-color: #fff;
}
@media (min-width: 600px) {
  .quotations-card:not(:last-child) {
    margin-bottom: 0rem;
  }
}
.quotations-card > span {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 0.8em;
  text-align: left;
}

.quotations-card b {
  text-align: right;
}
.quotations-card > span:not(:last-child) {
  padding-bottom: 1em;
}
.quotations-container,
.main-body {
  margin-top: 2rem;
}
.body-topbar {
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  padding: 1.5rem 1rem;
}
.body-topbar .span {
  width: 45%;
  display: flex;
}
.total {
  display: flex;
  justify-content: space-between;
}
.more-button {
  transform: rotateZ(90deg);
  padding: 0.3rem;
}
.table {
  /* margin-top: 1.5rem; */
  border-top: 1px solid #ddd;
}
@media (min-width: 600px) {
  .table {
    margin-top: 0rem;
  }

  .quotations-card > span {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8em;
  }
}
.content-container {
  display: flex;
}
.select-category {
  flex: 1;
  width: 100%;
  overflow-y: auto;
}
.select-category .topbar {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.select-category > .categories,
.grey-bg {
  height: 80vh;
  overflow-y: auto;
  padding: 1.5rem 1rem;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.navigation-buttons {
  margin-top: 0.7rem;
  padding-bottom: 2rem;
}
.sizes {
  display: flex;
}
.sizes > *:not(:last-child) {
  margin-bottom: 0.5em;
}

.foot-note {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.foot-note > * {
  width: 100%;
}
@media (min-width: 500px) {
  .categories {
    height: 80vh;
  }
  .foot-note {
    justify-content: flex-end;
  }
  .foot-note > * {
    width: 40%;
  }
  .sizes {
    flex-flow: row wrap;
  }
  .sizes > *:not(:last-child) {
    margin-bottom: 0;
  }
  .navigation-buttons {
    margin-top: 0.7rem;
    padding-bottom: 0rem;
  }
}
.sizes {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.table-row {
  font-size: 0.9rem;
  background-color: #fff;
}
.table-row:hover {
  opacity: 0.8;
  cursor: pointer;
}
