*::-webkit-scrollbar {
  height: 2px;
}
.container {
  background-color: #fff;
  width: 100%;
  padding: 1rem 1rem;
  height: 100%;
  /* overflow-y: auto; */
  overflow-x: auto;
  border-radius: 9px;
}
.table-wrap {
  /* border: 3px solid red; */
  overflow-x: auto;
}

.invoice {
  font-size: smaller;
  padding: 3rem;
  padding-top: 2rem;
}
.invoice__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1rem; */
  flex-flow: column nowrap;
}
.matadd {
  align-self: flex-end;
}
address {
  align-self: flex-start;
}
.invoice__header_invoice p {
  font-size: small;
  margin-bottom: 1rem;
}
.invoice-info {
  margin-bottom: 2rem;
}
.invoice-info p {
  margin: 0;
}
.invoice__header_invoice p span {
  font-weight: 700;
  display: block;
  font-size: 1rem;
}
.invoice > table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 1rem;
}

.invoice table th,
.invoice table td {
  padding: 0.25rem;
  text-align: start;
  border: 1px solid #ccc;
}
.invoice table tfoot td:nth-child(-n + 2) {
  border: none;
}

.invoice address > * {
  margin-bottom: 0;
}
.invoice__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 3rem;
}
.invoice__footer table tr td {
  text-align: end;
}
.topbar {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
}
.details-container {
  background-color: #fff;
  padding: 0.8rem;
  height: 100%;
}
.details-card-container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
.details-topbar {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  margin-bottom: 1rem;
}
@media (min-width: 600px) {
  .details-card-container {
    flex-flow: row wrap;
  }
  .details-topbar {
    justify-content: space-between;
    align-items: center;
  }
}

.details-topbar > *:not(:first-child) {
  padding-top: 0.5rem;
}
.details-card {
  background-color: #f9f9f9;
  width: 100%;
  padding: 1.3rem;
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: space-between;
}
.details-card b {
  text-align: right;
}
.details-card:not(:last-child) {
  margin-bottom: 1rem;
}

.details-card > span {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 0.8em;
}
.details-card b {
  text-transform: capitalize;
}
.details-card > span:not(:last-child) {
  padding-bottom: 1em;
}

.details-card > span b {
  text-align: left;
}
.table-row:hover {
  opacity: 0.8;
  cursor: pointer;
}

@media (min-width: 1000px) {
  .details-card {
    width: 49%;
  }

  .details-card:not(:last-child) {
    margin-bottom: 0rem;
  }
  .details-topbar > *:not(:first-child) {
    padding-top: 0rem;
  }
  .details-card > span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8em;
  }
  .details-card > span b {
    text-align: right;
  }
}
/* .footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
}
@media (min-width: 600px) {
  .footer {
    justify-content: flex-end;
  }
} */

.profile-card {
  background-color: #f9f9f9;
  padding: 1rem;
  width: 100%;
  margin: 2% auto 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.avatar {
  display: block;
  border-radius: 1rem;
  /* margin: 2rem auto; */
  position: relative;
  width: 49%;
}
.profile-content {
  margin-left: 2rem;
}
.circle {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 50px solid #00962c11;
}
.circle:nth-child(odd) {
  top: -40%;
  right: -20%;
}
.circle:nth-child(even) {
  bottom: -20%;
  left: -10%;
  border: 40px solid #00962c11;
}

.table {
  overflow-x: auto;
}

.table-container {
  overflow-x: auto;
  /* margin-right: 1rem; */
  width: 100%;
}
.table-row {
  font-size: 0.9rem;
  width: 100%;
  /* padding: 0 0.5rem; */
  background-color: #fff;
}
.table-row:hover {
  opacity: 0.8;
  cursor: pointer;
}
.quantity {
  text-transform: capitalize;
  font-size: larger;
}
