.auth-container {
  /* max-width: calc(450px + 2rem); */
  padding: 1rem;
  margin: 20px auto 0;
  /* min-height: 100vh; */
  margin-top: 2px;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
}
.auth-container h1 {
  font-size: 1.4rem;
  font-weight: 600;
  text-align: left;
  margin: 4rem 0 2rem;
}
.auth-container footer {
  text-align: center;
}
.logo {
  display: block;
  margin: 0 auto;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.auth-button__loading {
  max-height: 100%;
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.form__div {
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  justify-content: center;
  align-items: center;
}

.form {
  margin: 3rem 0 1rem;
  display: flex;
  flex-flow: row wrap;
}
.form h3 {
  margin-right: 1rem;
}
@media (min-width: 600px) {
  .auth-container {
    margin-top: 20px;
  }
  .form__div {
    display: flex;
  }
  .form {
    margin: 2rem 0 1rem;
    display: flex;
    flex-flow: row wrap;
  }
}

footer {
  font-size: 0.85rem;
}
